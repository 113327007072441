import { ApolloClient } from "@apollo/client/core";
import { WebSocketLink } from "@apollo/client/link/ws";
import { HttpLink, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { InMemoryCache } from "@apollo/client/cache";

const httpLink = new HttpLink({
  uri: "/v1/graphql",
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("terminal_token");
  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WEBSOCKET_URI,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: () => {
      const token = localStorage.getItem("terminal_token");
      return {
        headers: token && { Authorization: token },
      };
    },
  },
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  authLink.concat(httpLink),
);

export default new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
