import gql from "graphql-tag";

export const OPEN_ORDERS_SUBSCRIPTION = gql`
  subscription OpenOrders {
    orders_view(order_by: {status_updated_at: asc}) {
      id
      number
      status
      status_updated_at
    }
  }
`;
