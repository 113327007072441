import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useSubscription } from "@apollo/client";
import { deleteFromStorage } from "@rehooks/local-storage";
import { Howl } from "howler";

import colors from "../../theme/colors";
import { ORDER_STATUS_COLORS } from "./constants";
import { OPEN_ORDERS_SUBSCRIPTION } from "./orderQueries";
import readySound from "../../assets/ready_sound.wav";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Grid = styled.div`
  display: grid;
  ${(props) => `
    grid-template-columns: repeat(auto-fit, ${props.size.width}px);
    grid-auto-rows: ${props.size.height}px;
  `}
  grid-gap: 20px;
  padding: 20px;
`;

const Order = styled.div`
  background: ${(props) => props.color};
  color: ${colors.textOrderNumber};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
  font-size: 140px;
  font-weight: 700;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  height: 100px;
  width: 100px;
  color: ${colors.dodgerBlue};
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -50px;
  margin-top: -50px;
  border: 5px solid;
  border-radius: 50%;
  border-top-color: transparent;
  animation: ${rotate} 1s linear infinite;
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  > span {
    text-align: center;
    margin-bottom: 20px;
    font-size: 40px;
    font-family: sans-serif;
    color: ${(props) => (props.color === "error" ? colors.errorText : colors.white)};
  }

  .buttons {
    display: flex;
    flex-direction: row;
    width: 400px;
    height: 60px;
  }
`;

const Button = styled.button`
  width: 100%;
  height: 100%;
  margin: 0px 10px;
  border-radius: 10px;
  border: 4px solid ${(props) => props.color};
  background: none;
  color: ${colors.white};
  font-size: 20px;
  transition: 0.2s;

  :hover {
    background: ${(props) => props.color};
  }
`;

const sound = new Howl({
  src: [readySound],
});

const MIN_WIDTH = 360;
const MIN_HEIGHT = 220;

const calculateSize = (screenWidth, screenHeight) => {
  const columnCount = Math.max(
    1,
    Math.floor((screenWidth - 20) / (MIN_WIDTH + 20)),
  );
  const width = (screenWidth - 20) / columnCount - 20;
  const rowCount = Math.max(
    1,
    Math.floor((screenHeight - 20) / (MIN_HEIGHT + 20)),
  );
  const height = (screenHeight - 20) / rowCount - 20;
  return { width, height };
};

let lastReadyOrderAt;

const Orders = () => {
  const [showLogout, setShowLogout] = useState(false);
  const [size, setSize] = useState(
    calculateSize(window.innerWidth, window.innerHeight),
  );

  useEffect(() => {
    window.addEventListener("resize", () => {
      setSize(calculateSize(window.innerWidth, window.innerHeight));
    });
    return () => window.removeEventListener("resize", () => {
      setSize(calculateSize(window.innerWidth, window.innerHeight));
    });
  }, []);

  const openOrders = useSubscription(OPEN_ORDERS_SUBSCRIPTION);

  if (openOrders.error) {
    return (
      <DialogContainer color="error">
        <span>&#9762;</span>
        <span>Error: {openOrders.error.message}</span>
      </DialogContainer>
    );
  }

  if (openOrders.loading) return <Loader />;

  if (showLogout) {
    return (
      <DialogContainer color="logout">
        <span>Вы действительно хотите выйти?</span>
        <div className="buttons">
          <Button
            type="button"
            color={colors.errorText}
            onClick={() => setShowLogout(false)}
          >
            Отмена
          </Button>
          <Button
            type="button"
            color="green"
            onClick={() => deleteFromStorage("terminal_token")}
          >
            Выход
          </Button>
        </div>
      </DialogContainer>
    );
  }

  const readyOrders = openOrders.data.orders_view.filter(
    (order) => order.status === "ready",
  );
  const processingOrders = openOrders.data.orders_view.filter(
    (order) => order.status === "processing",
  );
  const receivedOrders = openOrders.data.orders_view.filter(
    (order) => order.status === "received",
  );
  const orders = [...readyOrders, ...processingOrders, ...receivedOrders];

  const currentLastReadyOrderAt = readyOrders.length > 0
    ? readyOrders[readyOrders.length - 1].status_updated_at : lastReadyOrderAt;
  if (currentLastReadyOrderAt > lastReadyOrderAt) {
    sound.play();
  }

  lastReadyOrderAt = currentLastReadyOrderAt;

  return (
    <Container onClick={() => setShowLogout(true)}>
      <Grid size={size}>
        {orders.map((order) => (
          <Order
            key={order.id}
            color={ORDER_STATUS_COLORS[order.status]}
            onClick={(e) => e.stopPropagation()}
          >
            {order.number}
          </Order>
        ))}
      </Grid>
    </Container>
  );
};

export default Orders;
