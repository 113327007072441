import React, { useState } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import axios from "axios";
import preval from "preval.macro";
import { ApolloProvider } from "@apollo/client";
import { writeStorage, useLocalStorage, deleteFromStorage } from "@rehooks/local-storage";

import TerminalLogin from "@salempos/terminal-login-page";

import GlobalStyle from "./theme/globalStyles";
import client from "./client";

import Orders from "./pages/orders/Orders";

const locale = window.navigator.userLanguage || window.navigator.language;
moment.locale(locale);

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("terminal_token");
    // eslint-disable-next-line no-param-reassign
    config.headers.authorization = token;
    return config;
  },
  (error) => Promise.reject(error),
);

const buildTime = preval`module.exports = new Date().toLocaleString([], {timeZoneName: "short"});`;
console.log(`Build time: ${buildTime}`);

const App = () => {
  const [error, setError] = useState(null);
  const [token] = useLocalStorage("terminal_token");

  const handleSubmit = (values) =>
    axios.post("auth/terminal/login", values).then((res) => {
      writeStorage("terminal_token", res.data.token);
    }).catch((err) => {
      setError(err.message);
      deleteFromStorage("terminal_token");
    });

  return token ? (
    <ApolloProvider client={client}>
      <GlobalStyle />
      <Orders />
    </ApolloProvider>
  ) : (
    <TerminalLogin type="tv" error={error} onSubmit={handleSubmit} />
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
